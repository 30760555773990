import React from 'react';
import './App.css';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';

import ImageRenderer from './ImageRenderer';
import NavGuideRenderer from './NavGuideRenderer';

function NavigationGuide() {
    const [renderSize, updateSize] = useState(12)
    const [update, setUpdate] = useState(null)
    const [clone, setClone] = useState(false)
    const [state, setState] = useState({
        "genes": [],
        "organs": [],
        "mice": [
            {
                "age": "p30",
                "gene": "GPR68",
                "id": 1,
                "mani_type": "reporter-gene-cross",
                "number": "2445",
                "sex": false,
                "spec": "+"
            },
            {
                "age": "p30",
                "gene": "GPR68",
                "id": 7,
                "mani_type": "reporter-gene-cross",
                "number": "2448",
                "sex": true,
                "spec": "+"
            },
            {
                "age": "p30",
                "gene": "GPR68",
                "id": 22,
                "mani_type": "reporter-gene-cross",
                "number": "2444",
                "sex": false,
                "spec": "-"
            },
            {
                "age": "p30",
                "gene": "GPR68",
                "id": 16,
                "mani_type": "reporter-gene-cross",
                "number": "2449",
                "sex": true,
                "spec": "-"
            }
        ],
        "selectedSampleType": {
            "value": "Histological",
            "label": "Histological"
        },
        "selectedGene": {
            "value": "GPR68",
            "label": "GPR68"
        },
        "selectedOrgan": {
            "value": "brain",
            "label": "brain"
        },
        "selectedMouse": {
            "value": "2445",
            "label": "♀  2445  pos"
        },
        "selectedSlice": 1,
        "selectedWavelength": "tdTomato",
        "loaded": null,
        "options": {
            "brightness": 10,
            "contrast": 0,
            "min": 0,
            "max": 255,
            "blend": 50,
            "opacityThreshold": 2
        },
        "slices": {
            "DAPI": [
                {
                    "age": "p30",
                    "checksum": "693baa5c0a9cc48126ff5bbfddabe06d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 1,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_DAPI_693baa5c0a9cc48126ff5bbfddabe06d.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_DAPI_693baa5c0a9cc48126ff5bbfddabe06d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_DAPI_693baa5c0a9cc48126ff5bbfddabe06d.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00001",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_DAPI_693baa5c0a9cc48126ff5bbfddabe06d.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "6c5786de60f6029e6cf03b6ba087f69a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 64,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_DAPI_6c5786de60f6029e6cf03b6ba087f69a.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_DAPI_6c5786de60f6029e6cf03b6ba087f69a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_DAPI_6c5786de60f6029e6cf03b6ba087f69a.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00002",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_DAPI_6c5786de60f6029e6cf03b6ba087f69a.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "55f1d9200b10a2215a3380ddd3943aae",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 73,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_DAPI_55f1d9200b10a2215a3380ddd3943aae.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_DAPI_55f1d9200b10a2215a3380ddd3943aae",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_DAPI_55f1d9200b10a2215a3380ddd3943aae.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00003",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_DAPI_55f1d9200b10a2215a3380ddd3943aae.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "33400d74a1461a3b88ad409557f5958e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 106,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_DAPI_33400d74a1461a3b88ad409557f5958e.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_DAPI_33400d74a1461a3b88ad409557f5958e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_DAPI_33400d74a1461a3b88ad409557f5958e.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00004",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_DAPI_33400d74a1461a3b88ad409557f5958e.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "488465b64461b9564f424bf4264044be",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 93,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_DAPI_488465b64461b9564f424bf4264044be.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_DAPI_488465b64461b9564f424bf4264044be",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_DAPI_488465b64461b9564f424bf4264044be.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00005",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_DAPI_488465b64461b9564f424bf4264044be.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "d4d1e7791717b65dcc8f64aebd9e9e71",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 102,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_DAPI_d4d1e7791717b65dcc8f64aebd9e9e71.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_DAPI_d4d1e7791717b65dcc8f64aebd9e9e71",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_DAPI_d4d1e7791717b65dcc8f64aebd9e9e71.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00006",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_DAPI_d4d1e7791717b65dcc8f64aebd9e9e71.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "88b0bcdb6a6388db414eee47939e3429",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 21,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_DAPI_88b0bcdb6a6388db414eee47939e3429.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_DAPI_88b0bcdb6a6388db414eee47939e3429",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_DAPI_88b0bcdb6a6388db414eee47939e3429.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00007",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_DAPI_88b0bcdb6a6388db414eee47939e3429.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "8ae57698536ab1ee45757e386fee6919",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 23,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_DAPI_8ae57698536ab1ee45757e386fee6919.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_DAPI_8ae57698536ab1ee45757e386fee6919",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_DAPI_8ae57698536ab1ee45757e386fee6919.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00008",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_DAPI_8ae57698536ab1ee45757e386fee6919.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c45a8ab579be54a78bdee7601342e73c",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 29,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_DAPI_c45a8ab579be54a78bdee7601342e73c.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_DAPI_c45a8ab579be54a78bdee7601342e73c",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_DAPI_c45a8ab579be54a78bdee7601342e73c.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00009",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_DAPI_c45a8ab579be54a78bdee7601342e73c.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c80df7dd72af18629e54448777812bb6",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 27,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_DAPI_c80df7dd72af18629e54448777812bb6.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_DAPI_c80df7dd72af18629e54448777812bb6",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_DAPI_c80df7dd72af18629e54448777812bb6.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00010",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_DAPI_c80df7dd72af18629e54448777812bb6.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0337a162f7fdbea474ade6408a890464",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 20,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_DAPI_0337a162f7fdbea474ade6408a890464.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_DAPI_0337a162f7fdbea474ade6408a890464",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_DAPI_0337a162f7fdbea474ade6408a890464.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00011",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_DAPI_0337a162f7fdbea474ade6408a890464.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a57cc540c40f0c9ae7ead21e4ae12d93",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 77,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_DAPI_a57cc540c40f0c9ae7ead21e4ae12d93.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_DAPI_a57cc540c40f0c9ae7ead21e4ae12d93",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_DAPI_a57cc540c40f0c9ae7ead21e4ae12d93.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00012",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_DAPI_a57cc540c40f0c9ae7ead21e4ae12d93.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "ef1f80e57b9925e7c9198dd67210e863",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 83,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_DAPI_ef1f80e57b9925e7c9198dd67210e863.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_DAPI_ef1f80e57b9925e7c9198dd67210e863",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_DAPI_ef1f80e57b9925e7c9198dd67210e863.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00013",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_DAPI_ef1f80e57b9925e7c9198dd67210e863.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "396195c7aff1d19b1789ebbd42b4c743",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 41,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_DAPI_396195c7aff1d19b1789ebbd42b4c743.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_DAPI_396195c7aff1d19b1789ebbd42b4c743",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_DAPI_396195c7aff1d19b1789ebbd42b4c743.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00014",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_DAPI_396195c7aff1d19b1789ebbd42b4c743.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "6733db3af7dc85d61e864009c60dbcb0",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 79,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_DAPI_6733db3af7dc85d61e864009c60dbcb0.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_DAPI_6733db3af7dc85d61e864009c60dbcb0",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_DAPI_6733db3af7dc85d61e864009c60dbcb0.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00015",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_DAPI_6733db3af7dc85d61e864009c60dbcb0.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "24e7e23cfa21c2145ccc9444ace60e9a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 69,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_DAPI_24e7e23cfa21c2145ccc9444ace60e9a.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_DAPI_24e7e23cfa21c2145ccc9444ace60e9a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_DAPI_24e7e23cfa21c2145ccc9444ace60e9a.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00016",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_DAPI_24e7e23cfa21c2145ccc9444ace60e9a.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "ebe4c9177b943debf4abc6d84134011d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 61,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_DAPI_ebe4c9177b943debf4abc6d84134011d.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_DAPI_ebe4c9177b943debf4abc6d84134011d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_DAPI_ebe4c9177b943debf4abc6d84134011d.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00017",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_DAPI_ebe4c9177b943debf4abc6d84134011d.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "cad3ec00c3b4fd6c0266e3025daece7c",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 32,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_DAPI_cad3ec00c3b4fd6c0266e3025daece7c.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_DAPI_cad3ec00c3b4fd6c0266e3025daece7c",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_DAPI_cad3ec00c3b4fd6c0266e3025daece7c.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00018",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_DAPI_cad3ec00c3b4fd6c0266e3025daece7c.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "7a7790e477c9192ae8bda59f0e524069",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 90,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_DAPI_7a7790e477c9192ae8bda59f0e524069.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_DAPI_7a7790e477c9192ae8bda59f0e524069",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_DAPI_7a7790e477c9192ae8bda59f0e524069.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00019",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_DAPI_7a7790e477c9192ae8bda59f0e524069.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "ae09830b74e523b68c71b2a1e1af7894",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 16,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_DAPI_ae09830b74e523b68c71b2a1e1af7894.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_DAPI_ae09830b74e523b68c71b2a1e1af7894",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_DAPI_ae09830b74e523b68c71b2a1e1af7894.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00020",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_DAPI_ae09830b74e523b68c71b2a1e1af7894.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "66b35075fc28fd1de620e4a02c2def27",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 33,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_DAPI_66b35075fc28fd1de620e4a02c2def27.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_DAPI_66b35075fc28fd1de620e4a02c2def27",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_DAPI_66b35075fc28fd1de620e4a02c2def27.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00021",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_DAPI_66b35075fc28fd1de620e4a02c2def27.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "03e688cd84f7971e1e5ea6a8a948cd7f",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 52,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_DAPI_03e688cd84f7971e1e5ea6a8a948cd7f.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_DAPI_03e688cd84f7971e1e5ea6a8a948cd7f",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_DAPI_03e688cd84f7971e1e5ea6a8a948cd7f.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00022",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_DAPI_03e688cd84f7971e1e5ea6a8a948cd7f.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "dc16628213c345376316e5164a833a26",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 54,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_DAPI_dc16628213c345376316e5164a833a26.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_DAPI_dc16628213c345376316e5164a833a26",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_DAPI_dc16628213c345376316e5164a833a26.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00023",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_DAPI_dc16628213c345376316e5164a833a26.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "fb8705184c37d6e5a60752f77571ba3a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 17,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_DAPI_fb8705184c37d6e5a60752f77571ba3a.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_DAPI_fb8705184c37d6e5a60752f77571ba3a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_DAPI_fb8705184c37d6e5a60752f77571ba3a.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00024",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_DAPI_fb8705184c37d6e5a60752f77571ba3a.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "13492882c778ee89bce2f9dcb389a742",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 44,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_DAPI_13492882c778ee89bce2f9dcb389a742.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_DAPI_13492882c778ee89bce2f9dcb389a742",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_DAPI_13492882c778ee89bce2f9dcb389a742.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00025",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_DAPI_13492882c778ee89bce2f9dcb389a742.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "19051a65e50520c871d5af73d285fae5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 82,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_DAPI_19051a65e50520c871d5af73d285fae5.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_DAPI_19051a65e50520c871d5af73d285fae5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_DAPI_19051a65e50520c871d5af73d285fae5.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00026",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_DAPI_19051a65e50520c871d5af73d285fae5.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c6055c1cc95d0ae29ab076cbbbbc41c5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 7,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_DAPI_c6055c1cc95d0ae29ab076cbbbbc41c5.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_DAPI_c6055c1cc95d0ae29ab076cbbbbc41c5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_DAPI_c6055c1cc95d0ae29ab076cbbbbc41c5.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00027",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_DAPI_c6055c1cc95d0ae29ab076cbbbbc41c5.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "84f4a7dd15a73a6587dcde36f2707a97",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 89,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_DAPI_84f4a7dd15a73a6587dcde36f2707a97.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_DAPI_84f4a7dd15a73a6587dcde36f2707a97",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_DAPI_84f4a7dd15a73a6587dcde36f2707a97.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00028",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_DAPI_84f4a7dd15a73a6587dcde36f2707a97.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "4441c6eac9671a17669a751f54508b5e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 56,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_DAPI_4441c6eac9671a17669a751f54508b5e.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_DAPI_4441c6eac9671a17669a751f54508b5e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_DAPI_4441c6eac9671a17669a751f54508b5e.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00029",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_DAPI_4441c6eac9671a17669a751f54508b5e.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a97d207dcaf6f36fd53d57194989273a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 68,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_DAPI_a97d207dcaf6f36fd53d57194989273a.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_DAPI_a97d207dcaf6f36fd53d57194989273a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_DAPI_a97d207dcaf6f36fd53d57194989273a.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00030",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_DAPI_a97d207dcaf6f36fd53d57194989273a.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "e76e12e65d63a00ed633cea9a2c18ce5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 55,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_DAPI_e76e12e65d63a00ed633cea9a2c18ce5.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_DAPI_e76e12e65d63a00ed633cea9a2c18ce5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_DAPI_e76e12e65d63a00ed633cea9a2c18ce5.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00031",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_DAPI_e76e12e65d63a00ed633cea9a2c18ce5.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "69e5479aed979e1be339b8daa4d678ea",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 14,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_DAPI_69e5479aed979e1be339b8daa4d678ea.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_DAPI_69e5479aed979e1be339b8daa4d678ea",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_DAPI_69e5479aed979e1be339b8daa4d678ea.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00032",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_DAPI_69e5479aed979e1be339b8daa4d678ea.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "ed4cb54d9cf158b4c8395ba830e5e0ff",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 87,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_DAPI_ed4cb54d9cf158b4c8395ba830e5e0ff.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_DAPI_ed4cb54d9cf158b4c8395ba830e5e0ff",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_DAPI_ed4cb54d9cf158b4c8395ba830e5e0ff.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00033",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_DAPI_ed4cb54d9cf158b4c8395ba830e5e0ff.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "d2b205a97f531131b21dbc6839fd0b07",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 81,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_DAPI_d2b205a97f531131b21dbc6839fd0b07.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_DAPI_d2b205a97f531131b21dbc6839fd0b07",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_DAPI_d2b205a97f531131b21dbc6839fd0b07.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00034",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_DAPI_d2b205a97f531131b21dbc6839fd0b07.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "6c557a43e2ca3419c669f8911ebb8d2f",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 19,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_DAPI_6c557a43e2ca3419c669f8911ebb8d2f.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_DAPI_6c557a43e2ca3419c669f8911ebb8d2f",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_DAPI_6c557a43e2ca3419c669f8911ebb8d2f.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00035",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_DAPI_6c557a43e2ca3419c669f8911ebb8d2f.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "9eb251f15d6553191f482d1863952fc8",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 12,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_DAPI_9eb251f15d6553191f482d1863952fc8.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_DAPI_9eb251f15d6553191f482d1863952fc8",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_DAPI_9eb251f15d6553191f482d1863952fc8.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00036",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_DAPI_9eb251f15d6553191f482d1863952fc8.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2976ed0525c5e4f4ee0df6f4d233eeb0",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 103,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_DAPI_2976ed0525c5e4f4ee0df6f4d233eeb0.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_DAPI_2976ed0525c5e4f4ee0df6f4d233eeb0",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_DAPI_2976ed0525c5e4f4ee0df6f4d233eeb0.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00037",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_DAPI_2976ed0525c5e4f4ee0df6f4d233eeb0.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "af178787b7d31086f80555ae13669b0d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 72,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_DAPI_af178787b7d31086f80555ae13669b0d.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_DAPI_af178787b7d31086f80555ae13669b0d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_DAPI_af178787b7d31086f80555ae13669b0d.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00038",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_DAPI_af178787b7d31086f80555ae13669b0d.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "8be1f328c6a4eb716d2a9063ff5e203e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 66,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_DAPI_8be1f328c6a4eb716d2a9063ff5e203e.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_DAPI_8be1f328c6a4eb716d2a9063ff5e203e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_DAPI_8be1f328c6a4eb716d2a9063ff5e203e.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00039",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_DAPI_8be1f328c6a4eb716d2a9063ff5e203e.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "7dd2e7bc9744acd1fc926c8f0d725ef9",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 11,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_DAPI_7dd2e7bc9744acd1fc926c8f0d725ef9.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_DAPI_7dd2e7bc9744acd1fc926c8f0d725ef9",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_DAPI_7dd2e7bc9744acd1fc926c8f0d725ef9.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00040",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_DAPI_7dd2e7bc9744acd1fc926c8f0d725ef9.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "247aff8dfe0f3fc48fe7dc3fefd7b2f7",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 59,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_DAPI_247aff8dfe0f3fc48fe7dc3fefd7b2f7.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_DAPI_247aff8dfe0f3fc48fe7dc3fefd7b2f7",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_DAPI_247aff8dfe0f3fc48fe7dc3fefd7b2f7.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00041",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_DAPI_247aff8dfe0f3fc48fe7dc3fefd7b2f7.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "ca5474e96cf5d37b7186d5d16a5e4d17",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 13,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_DAPI_ca5474e96cf5d37b7186d5d16a5e4d17.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_DAPI_ca5474e96cf5d37b7186d5d16a5e4d17",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_DAPI_ca5474e96cf5d37b7186d5d16a5e4d17.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00042",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_DAPI_ca5474e96cf5d37b7186d5d16a5e4d17.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "7a7bd451ca985f1bdc520ddb590f1e7a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 45,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_DAPI_7a7bd451ca985f1bdc520ddb590f1e7a.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_DAPI_7a7bd451ca985f1bdc520ddb590f1e7a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_DAPI_7a7bd451ca985f1bdc520ddb590f1e7a.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00043",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_DAPI_7a7bd451ca985f1bdc520ddb590f1e7a.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0df403d37ab35be77674b5b4831b09b8",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 39,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_DAPI_0df403d37ab35be77674b5b4831b09b8.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_DAPI_0df403d37ab35be77674b5b4831b09b8",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_DAPI_0df403d37ab35be77674b5b4831b09b8.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00044",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_DAPI_0df403d37ab35be77674b5b4831b09b8.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "1c7086e41544e97bbefaf6e2b82adced",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 85,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_DAPI_1c7086e41544e97bbefaf6e2b82adced.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_DAPI_1c7086e41544e97bbefaf6e2b82adced",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_DAPI_1c7086e41544e97bbefaf6e2b82adced.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00045",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_DAPI_1c7086e41544e97bbefaf6e2b82adced.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "53205f912b78e4ddd4a8ce3f269207a7",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 6,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_DAPI_53205f912b78e4ddd4a8ce3f269207a7.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_DAPI_53205f912b78e4ddd4a8ce3f269207a7",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_DAPI_53205f912b78e4ddd4a8ce3f269207a7.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00046",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_DAPI_53205f912b78e4ddd4a8ce3f269207a7.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "5a93910614b477ff254136602995df4e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 57,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_DAPI_5a93910614b477ff254136602995df4e.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_DAPI_5a93910614b477ff254136602995df4e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_DAPI_5a93910614b477ff254136602995df4e.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00047",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_DAPI_5a93910614b477ff254136602995df4e.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "490d6bde88a2b493abee5c3345ea3f7e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 5,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_DAPI_490d6bde88a2b493abee5c3345ea3f7e.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_DAPI_490d6bde88a2b493abee5c3345ea3f7e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_DAPI_490d6bde88a2b493abee5c3345ea3f7e.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00048",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_DAPI_490d6bde88a2b493abee5c3345ea3f7e.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a73d0fca919bbd76ba356e37a220c669",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 101,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_DAPI_a73d0fca919bbd76ba356e37a220c669.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_DAPI_a73d0fca919bbd76ba356e37a220c669",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_DAPI_a73d0fca919bbd76ba356e37a220c669.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00049",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_DAPI_a73d0fca919bbd76ba356e37a220c669.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "26b5cfc8b5a1c6aeb1987312a90a6cb8",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 43,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_DAPI_26b5cfc8b5a1c6aeb1987312a90a6cb8.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_DAPI_26b5cfc8b5a1c6aeb1987312a90a6cb8",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_DAPI_26b5cfc8b5a1c6aeb1987312a90a6cb8.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00050",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_DAPI_26b5cfc8b5a1c6aeb1987312a90a6cb8.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "48bb903e6218f6c1127a75a611850163",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 26,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_DAPI_48bb903e6218f6c1127a75a611850163.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_DAPI_48bb903e6218f6c1127a75a611850163",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_DAPI_48bb903e6218f6c1127a75a611850163.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00051",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_DAPI_48bb903e6218f6c1127a75a611850163.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "18477bf13b1582de602e476f408eda4d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 65,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_DAPI_18477bf13b1582de602e476f408eda4d.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_DAPI_18477bf13b1582de602e476f408eda4d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_DAPI_18477bf13b1582de602e476f408eda4d.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00052",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_DAPI_18477bf13b1582de602e476f408eda4d.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "4f851d7d16d2661e9904f6ab239ca0da",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 53,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_DAPI_4f851d7d16d2661e9904f6ab239ca0da.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_DAPI_4f851d7d16d2661e9904f6ab239ca0da",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_DAPI_4f851d7d16d2661e9904f6ab239ca0da.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00053",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_DAPI_4f851d7d16d2661e9904f6ab239ca0da.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "93b282a2864719c1ee84ad8aa662e7c5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 95,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_DAPI_93b282a2864719c1ee84ad8aa662e7c5.jpg",
                    "img_big_RI": "",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_DAPI_93b282a2864719c1ee84ad8aa662e7c5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_DAPI_93b282a2864719c1ee84ad8aa662e7c5.png",
                    "img_small_RI": "",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00054",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_DAPI_93b282a2864719c1ee84ad8aa662e7c5.tif",
                    "uberon": "955",
                    "wavelength": "DAPI",
                    "z_step_size": null
                }
            ],
            "tdTomato": [
                {
                    "age": "p30",
                    "checksum": "6e7659e6f86340d698772cec77fcc6f2",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 84,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00001",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00001_10x_Olympus_tdTomato_6e7659e6f86340d698772cec77fcc6f2.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a2ae2bc075f67538f0a1f0130ee59d7d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 75,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00002",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00002_10x_Olympus_tdTomato_a2ae2bc075f67538f0a1f0130ee59d7d.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "3906350dea7905ad53eaa10d63ef035d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 47,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00003",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00003_10x_Olympus_tdTomato_3906350dea7905ad53eaa10d63ef035d.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0ee61002abe5e1087793e6997040748f",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 42,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00004",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00004_10x_Olympus_tdTomato_0ee61002abe5e1087793e6997040748f.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a009ca3e4c75c314b447de73073dd26e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 94,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00005",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00005_10x_Olympus_tdTomato_a009ca3e4c75c314b447de73073dd26e.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0edd201837caa45ce43cf7a07d14afed",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 25,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00006",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00006_10x_Olympus_tdTomato_0edd201837caa45ce43cf7a07d14afed.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0caf8028aa89b8415186675300c6ac28",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 31,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00007",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00007_10x_Olympus_tdTomato_0caf8028aa89b8415186675300c6ac28.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "93b945d293bbd0321220febd292812ab",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 22,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00008",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00008_10x_Olympus_tdTomato_93b945d293bbd0321220febd292812ab.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "137e6b36293591ab3fe08f96d0ce27bc",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 2,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00009",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00009_10x_Olympus_tdTomato_137e6b36293591ab3fe08f96d0ce27bc.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "f5c3b2f6177b1ecd68d416e17c0d8724",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 18,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00010",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00010_10x_Olympus_tdTomato_f5c3b2f6177b1ecd68d416e17c0d8724.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "063a04e5839ebc2de3dcb31c3ec9c5b9",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 76,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00011",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00011_10x_Olympus_tdTomato_063a04e5839ebc2de3dcb31c3ec9c5b9.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0b90aa6b431dfc5c10ba65bcdde4ec87",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 46,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00012",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00012_10x_Olympus_tdTomato_0b90aa6b431dfc5c10ba65bcdde4ec87.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2ed5d1cc154974c59fe386d9f7809716",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 78,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00013",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00013_10x_Olympus_tdTomato_2ed5d1cc154974c59fe386d9f7809716.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "4c768d5eb725e766b856681cca2fd4e3",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 67,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00014",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00014_10x_Olympus_tdTomato_4c768d5eb725e766b856681cca2fd4e3.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "0c5a71879e5da476e3f5aece9850d894",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 28,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00015",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00015_10x_Olympus_tdTomato_0c5a71879e5da476e3f5aece9850d894.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "b4538865a2274200b183e9c3307aacfc",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 105,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00016",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00016_10x_Olympus_tdTomato_b4538865a2274200b183e9c3307aacfc.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "97ea9b08c2d3fb10d780471b7bcfbb01",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 92,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00017",
                    "slide_number": "1",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_1_00017_10x_Olympus_tdTomato_97ea9b08c2d3fb10d780471b7bcfbb01.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "d410922d1d99cce929fb0128049f6636",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 36,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00018",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00018_10x_Olympus_tdTomato_d410922d1d99cce929fb0128049f6636.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "d9561347868ebb8d9bfe0a21e82f0daa",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 15,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00019",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00019_10x_Olympus_tdTomato_d9561347868ebb8d9bfe0a21e82f0daa.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "3885b74b4430dd6e2b09d0e4d4c3d0a2",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 4,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00020",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00020_10x_Olympus_tdTomato_3885b74b4430dd6e2b09d0e4d4c3d0a2.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "65e78713834bbfc57b38a5de3690acd2",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 49,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00021",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00021_10x_Olympus_tdTomato_65e78713834bbfc57b38a5de3690acd2.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c27c7888510eeec0709e462a33a22ac9",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 80,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00022",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00022_10x_Olympus_tdTomato_c27c7888510eeec0709e462a33a22ac9.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "8d71e50cf779c5225b8bec6382e32d3b",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 91,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00023",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00023_10x_Olympus_tdTomato_8d71e50cf779c5225b8bec6382e32d3b.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "02794db39985ac20dee67ff122f5d78a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 35,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00024",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00024_10x_Olympus_tdTomato_02794db39985ac20dee67ff122f5d78a.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c8fe25d655a930afa247ccd231660475",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 107,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00025",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00025_10x_Olympus_tdTomato_c8fe25d655a930afa247ccd231660475.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "b6ecfb0f8ab29c0c6365a7987d4ec463",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 3,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00026",
                    "slide_number": "2",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_2_00026_10x_Olympus_tdTomato_b6ecfb0f8ab29c0c6365a7987d4ec463.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "44660772ae86e033468b996ebf73d28a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 71,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00027",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00027_10x_Olympus_tdTomato_44660772ae86e033468b996ebf73d28a.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2ffc32ef68050f1a213a8709066e269f",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 88,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00028",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00028_10x_Olympus_tdTomato_2ffc32ef68050f1a213a8709066e269f.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "b1ab79fc8cd33e88747359a6084a876d",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 51,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00029",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00029_10x_Olympus_tdTomato_b1ab79fc8cd33e88747359a6084a876d.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2fc5f235e9d49f5f9c44f28b36cacb25",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 37,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00030",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00030_10x_Olympus_tdTomato_2fc5f235e9d49f5f9c44f28b36cacb25.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a1ac4ddacfd7b741be722a2ddc9e6f0e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 58,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00031",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00031_10x_Olympus_tdTomato_a1ac4ddacfd7b741be722a2ddc9e6f0e.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "23e4b821b3b563e4da61e55af9e84158",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 96,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00032",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00032_10x_Olympus_tdTomato_23e4b821b3b563e4da61e55af9e84158.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "3a4d54470c5732dce3126222466f9796",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 60,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00033",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00033_10x_Olympus_tdTomato_3a4d54470c5732dce3126222466f9796.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "1e5229f6c26fb6fad9ea11c227ee3853",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 30,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00034",
                    "slide_number": "3",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_3_00034_10x_Olympus_tdTomato_1e5229f6c26fb6fad9ea11c227ee3853.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "5ea11490697db4a26986a8fa764e8660",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 108,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00035",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00035_10x_Olympus_tdTomato_5ea11490697db4a26986a8fa764e8660.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "1e89763db4cf2f1ddb507671f03e179b",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 100,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00036",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00036_10x_Olympus_tdTomato_1e89763db4cf2f1ddb507671f03e179b.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c874b1b31c4bd31d2e613736c28dd150",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 48,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00037",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00037_10x_Olympus_tdTomato_c874b1b31c4bd31d2e613736c28dd150.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "088f38c543616b11e00cc6fe47883ae5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 104,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00038",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00038_10x_Olympus_tdTomato_088f38c543616b11e00cc6fe47883ae5.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "43571e390f13c8fb12b53503f781e85b",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 70,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00039",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00039_10x_Olympus_tdTomato_43571e390f13c8fb12b53503f781e85b.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "aa154c4bda14b4ddeaec178339e781e6",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 63,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00040",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00040_10x_Olympus_tdTomato_aa154c4bda14b4ddeaec178339e781e6.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "aa2cce153965a68a7a6872977f7a77f5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 99,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00041",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00041_10x_Olympus_tdTomato_aa2cce153965a68a7a6872977f7a77f5.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "825ceb9dd68d6e26285d4bde22eccea1",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 40,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00042",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00042_10x_Olympus_tdTomato_825ceb9dd68d6e26285d4bde22eccea1.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2c9fae700fe19fe2cd48f24c21723a12",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 74,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00043",
                    "slide_number": "4",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_4_00043_10x_Olympus_tdTomato_2c9fae700fe19fe2cd48f24c21723a12.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a57b48818e1d8147b11088ee18ba3934",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 10,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00044",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00044_10x_Olympus_tdTomato_a57b48818e1d8147b11088ee18ba3934.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a8f8a6a376564bf48206e268094c142a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 8,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00045",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00045_10x_Olympus_tdTomato_a8f8a6a376564bf48206e268094c142a.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a7dd77956bb38191a7a9c97949bd091e",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 24,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00046",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00046_10x_Olympus_tdTomato_a7dd77956bb38191a7a9c97949bd091e.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "812582a65bfcd1a35ebe0dafec19fe61",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 86,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00047",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00047_10x_Olympus_tdTomato_812582a65bfcd1a35ebe0dafec19fe61.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "49a9cc8486b13364bc8c2535c2e744ff",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 62,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00048",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00048_10x_Olympus_tdTomato_49a9cc8486b13364bc8c2535c2e744ff.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "389a752d03104f3b3cdcad1e624d1f55",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 98,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00049",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00049_10x_Olympus_tdTomato_389a752d03104f3b3cdcad1e624d1f55.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "d832e512575b04b521115f5a224e9523",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 50,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00050",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00050_10x_Olympus_tdTomato_d832e512575b04b521115f5a224e9523.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "a4f6e042ce6a3572a81251b9422fffe3",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 34,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00051",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00051_10x_Olympus_tdTomato_a4f6e042ce6a3572a81251b9422fffe3.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "2503f73f054bbc4d5b87beed57ecbef5",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 97,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00052",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00052_10x_Olympus_tdTomato_2503f73f054bbc4d5b87beed57ecbef5.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "3982205db5bdd227df50d9a5c9f77b8a",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 38,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00053",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00053_10x_Olympus_tdTomato_3982205db5bdd227df50d9a5c9f77b8a.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                },
                {
                    "age": "p30",
                    "checksum": "c7dfc5080b4b7c7fecbf74f7a2e2dc27",
                    "experiment": "Ai9",
                    "gene": "GPR68",
                    "genotype_gene": 1,
                    "genotype_reporter": 1,
                    "id": 9,
                    "img_big": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27.jpg",
                    "img_big_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27_RI.jpg",
                    "img_no_ext": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27",
                    "img_small": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27.png",
                    "img_small_RI": "GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27_RI.webp",
                    "instrument": "Olympus",
                    "mani_type": "reporter-gene-cross",
                    "mouse_number": "2445",
                    "objective": "10x",
                    "organ": "brain",
                    "orientation": "c",
                    "sex": "female",
                    "slice_id": "00054",
                    "slide_number": "5",
                    "tif_url": "/images/1/2445/GPR68_Ai9_1_1_2445_f_p30_reporter-gene-cross_brain_955_c_5_00054_10x_Olympus_tdTomato_c7dfc5080b4b7c7fecbf74f7a2e2dc27.tif",
                    "uberon": "955",
                    "wavelength": "tdTomato",
                    "z_step_size": null
                }
            ]
        },
        "lut": {
            "tdTomato": "grayscale",
            "DAPI": "grayscale",
            "GFP": "grayscale"
        },
        "selections": {
            "Histological": {
                "genes": {
                    "GPR68": {
                        "organs": [{

                            "brain": {
                                "mice": [

                                ]
                            },
                        }
                        ]
                    },
                }


            },

        },
        "sampleTypes": [],
        "optionsLoaded": null,
        "activeLayers": [
            "tdTomato"
        ],
        "controlsHidden": false,
    }
    );

    function splitScreen(newState) {
        setState(newState)
        if (renderSize === 12) {
            setClone(true)
            updateSize(6)
        } else {
            setClone(false)
            updateSize(12)
        }
    }





    useEffect(() => {
        //leaflet doesn't like changing the col size. Force a window resize to trigger map update
        global.dispatchEvent(new Event('resize'));

    }, [renderSize])


    return (
        <Container fluid className="imageBrowser">
            <Row>
                <Col lg={12}>
                    <NavGuideRenderer splitScreen={splitScreen} main={true} renderSize={renderSize} state={state} />
                </Col>

            </Row>
        </Container>
    );
}

export default NavigationGuide;
